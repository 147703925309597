import { Injectable } from '@angular/core';
import { LocalStorageService } from './local.storage.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private readonly storage: LocalStorageService) {}

  get(key: string): any {
    return this.storage.get(key);
  }

  save(key: string, data: any) {
    return this.storage.save(key, data);
  }

  remove(key: string) {
    return this.storage.remove(key);
  }
}
