import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {}

  get(url: string, params?: any): Observable<any> {
    const request = {
      method: 'get',
      url: this.makeFullUrl(url),
      httpOptions: this.makeHttpOptions(undefined, params),
    };
    return this.request(request);
  }

  post(url: string, body?: any): Observable<any> {
    const request = {
      method: 'post',
      url: this.makeFullUrl(url),
      httpOptions: this.makeHttpOptions(body),
    };
    return this.request(request);
  }

  put(url: string, body?: any): Observable<any> {
    const request = {
      method: 'put',
      url: this.makeFullUrl(url),
      httpOptions: this.makeHttpOptions(body),
    };
    return this.request(request);
  }

  request(request: any): Observable<any> {
    return this.http.request(request.method, request.url, request.httpOptions);
  }

  private makeFullUrl(url: string, useBaseSite = false): string {
    return `${this.configService.readConfig().apiUrl}/${url}`;
  }

  private makeHttpOptions(body?: any, params?: any): any {
    const headers: any = { 'Content-Type': 'application/json' };
    const httpOptions: any = {
      headers: new HttpHeaders(headers),
    };

    if (body) {
      httpOptions.body = body;
    }

    if (params) {
      httpOptions.params = params;
    }

    return httpOptions;
  }
}
