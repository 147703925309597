import { inject, Injectable } from '@angular/core';
import { Contravention, ContraventionProps } from '../entity/contravention';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class ContraventionStoreService {
  private storageService = inject(StorageService);
  private contraventionStoreMapper = new ContraventionStoreMapper();
  private contraventionKey = 'contravention';

  storeContravention(contravention: Contravention) {
    this.storageService.save(this.contraventionKey, contravention.getAllProps());
  }

  getContravention(): Contravention {
    const contraventionStored = this.storageService.get(this.contraventionKey) as ContraventionProps;
    if (!contraventionStored) {
      return null;
    }
    return this.contraventionStoreMapper.toEntity(contraventionStored);
  }

  removeContravention() {
    this.storageService.remove(this.contraventionKey);
  }
}

class ContraventionStoreMapper {
  toEntity(contravention: ContraventionProps): Contravention {
    return new Contravention({
      network: contravention.network,
      createdDate: new Date(contravention.createdDate),
      violationId: contravention.violationId,
      createdTime: contravention.createdTime,
      paymentStatusId: contravention.paymentStatusId,
      paymentTypeId: contravention.paymentTypeId,
      paymentScheduled: contravention.paymentScheduled,
      stopName: contravention.stopName,
      routeName: contravention.routeName,
      violationFine: contravention.violationFine,
      violationAdminFees: contravention.violationAdminFees,
      violatorDateOfBirth: new Date(contravention.violatorDateOfBirth),
      violatorFirstname: contravention.violatorFirstname,
      violatorLastname: contravention.violatorLastname,
      violationTypeId: contravention.violationTypeId,
      violationTypeName: contravention.violationTypeName,
      email: contravention.email,
    });
  }
}
