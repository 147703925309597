import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  get(path: string): any {
    try {
      return JSON.parse(localStorage.getItem(path));
    } catch (err) {
      console.error(err);
    }
  }

  save(path: string, data: any) {
    try {
      localStorage.setItem(path, JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  }

  remove(path: string) {
    try {
      localStorage.removeItem(path);
    } catch (err) {
      console.error(err);
    }
  }
}
