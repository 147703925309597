import { PaymentScheduled } from '../models/contravention.model';
import { TranslateCode } from '../enums/translate-code.enum';

export type ContraventionProps = {
  network: string;
  createdDate: Date;
  violationId: string;
  createdTime: string;
  paymentStatusId: number;
  paymentTypeId: number;
  paymentScheduled: PaymentScheduled[] | null;
  stopName: string;
  routeName: string;
  violationFine: number;
  violationAdminFees: number;
  violatorDateOfBirth: Date;
  violatorFirstname: string;
  violatorLastname: string;
  violationTypeId: number;
  violationTypeName: string;
  email: string;
};

export class Contravention {
  constructor(public props: ContraventionProps) {}

  isInPayment(): boolean {
    return this.props.paymentStatusId === TranslateCode.INPAYMENT;
  }

  getViolationAmount(): number {
    return this.props.violationFine + this.props.violationAdminFees;
  }

  getAmountPayed(): number {
    return (
      this.props.paymentScheduled?.reduce((acc: number, paymentScheduled: PaymentScheduled) => {
        if (paymentScheduled.is_paid) {
          acc += paymentScheduled.amount;
        }
        return acc;
      }, 0) ?? 0
    );
  }

  getPaymentScheduledToPay(): PaymentScheduled[] {
    return this.props.paymentScheduled?.reduce((acc: PaymentScheduled[], paymentScheduled: PaymentScheduled) => {
      if (!paymentScheduled.is_paid) {
        acc.push(paymentScheduled);
      }
      return acc;
    }, []);
  }

  getViolationId(): string {
    return this.props.violationId;
  }

  getNetwork(): string {
    return this.props.network;
  }

  getAllProps(): ContraventionProps {
    return this.props;
  }
}
